import React from 'react';

import AuthLayout from '../../components/auth-layout';
import RegistrationSpecialHandling from '../../components/registration-special-handling';
import { useTranslation } from '../../hooks/useTranslation';
import Helmet from 'react-helmet';

export default () => {
  const { t } = useTranslation();
  const title = t('REGISTRATION_SPECIAL_HANDLING_HEADER');

  return (
    <>
      <Helmet>
        <title>{t('REGISTRATION')}</title>
      </Helmet>
      <AuthLayout title={title} showLeft={false}>
        <RegistrationSpecialHandling />
      </AuthLayout>
    </>
  );
};
