import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import React from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import Alerts from '../alerts';
import Button from '../buttons';
import useStyles from './RegistrationSpecialHandling.styles';

const AlertMessage = (props: { classes: any }) => {
  const { t, richT } = useTranslation();
  const { classes } = props;

  return (
    <Typography
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      className={`${classes.colorTextPrimary} ${classes.alertText}`}
      color={'textPrimary'}
      variant={'body2'}
    >
      {richT('REGISTRATION_SPECIAL_HANDLING_TEXT', {
        RESIDENTIAL_CUSTOMER_SERVICE_NUMBER: t(
          'RESIDENTIAL_CUSTOMER_SERVICE_NUMBER',
        ),
        BUSINESS_CUSTOMER_SERVICE_NUMBER: t('BUSINESS_CUSTOMER_SERVICE_NUMBER'),
      })}
    </Typography>
  );
};

export default () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
      alignItems={'flex-start'}
      direction={'column'}
    >
      <Grid item xs={12}>
        <Alerts
          disableColorOverride={true}
          severity={'error'}
          variant={'outlined'}
          title={t('SPECIAL_HANDLING')}
          message={<AlertMessage classes={classes} />}
          isOpen
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onMouseDown={() => navigate(ROUTES.SIGN_IN)}
            type={'submit'}
            variant={'contained'}
            color={'primary'}
            size={'large'}
          >
            {'OK'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
